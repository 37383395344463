import React from "react";
import styled from "@emotion/styled/";
import icon from "../../../assets/common/store.svg"

export default function FooterOnlineButton() {
    return (
        <Container>
            <a className="online-button" href="https://shop.knitology.net/">Online Shop</a>
        </Container>
    );
}
const Container = styled.div`
  
  .online-button {
    display: inline-block;
  }

  a.online-button {
    padding: 0em 1.6em;
    margin: 10px 0;
    //display: block;

    //font-family: Didot;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    line-height: 47px;
    color: #fff;
    
    background-color: transparent;
    text-align: center;
    border: #B7B7B7 solid 1px;
  }

  a.online-button:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -3px 12px 0 0;

    background: url(${icon}) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  a.online-button:hover {
    background: #B7B7B7;
  }

`;
