import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

interface LogoProps {
  onClick?: () => void
}

export default function Logo(props: LogoProps) {
  return (
    <LogoWrap onClick={props.onClick} to="/">
      <svg
        id="Group_4409"
        data-name="Group 4409"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-svg"
        width="223.335"
        height="20.974"
        viewBox="0 0 223.335 20.974"
      >
        <g id="Group_4408" data-name="Group 4408" transform="translate(0 0)">
          <path
            id="Path_11286"
            data-name="Path 11286"
            d="M160.334,466.514l7.37-8.489h4.936l-8.486,9.268,8.936,10.8h-5.177l-7.13-8.992-.449.446v8.546h-3.849V458.025h3.849Z"
            transform="translate(-156.485 -457.572)"
            fill="currentColor"
          />
          <path
            id="Path_11287"
            data-name="Path 11287"
            d="M175.819,478.093V458.025h2.664l12.326,13.392V458.025h3.847v20.068h-2.666l-12.319-13.418v13.418Z"
            transform="translate(-148.259 -457.572)"
            fill="currentColor"
          />
          <path
            id="Path_11288"
            data-name="Path 11288"
            d="M202.847,458.025v20.068H199V458.025Z"
            transform="translate(-138.396 -457.572)"
            fill="currentColor"
          />
          <path
            id="Path_11289"
            data-name="Path 11289"
            d="M218.592,461.4v16.7h-3.846V461.4h-4.519v-3.371h12.881V461.4Z"
            transform="translate(-133.62 -457.572)"
            fill="currentColor"
          />
          <path
            id="Path_11290"
            data-name="Path 11290"
            d="M236.143,457.707a11.749,11.749,0,0,1,4.351.783,10.082,10.082,0,0,1,3.381,2.184,9.743,9.743,0,0,1,2.2,3.326,11.648,11.648,0,0,1,0,8.424,9.612,9.612,0,0,1-2.2,3.31,10.135,10.135,0,0,1-3.381,2.168,12.526,12.526,0,0,1-8.7,0,10.09,10.09,0,0,1-3.384-2.168,9.7,9.7,0,0,1-2.2-3.31,11.727,11.727,0,0,1,0-8.424,9.838,9.838,0,0,1,2.2-3.326,10.037,10.037,0,0,1,3.384-2.184A11.717,11.717,0,0,1,236.143,457.707Zm0,17.428a6.9,6.9,0,0,0,2.691-.513,6.268,6.268,0,0,0,2.105-1.428,6.608,6.608,0,0,0,1.387-2.18,8.155,8.155,0,0,0,0-5.6,6.713,6.713,0,0,0-1.387-2.2,6.165,6.165,0,0,0-2.105-1.444,7.359,7.359,0,0,0-5.385,0,6.164,6.164,0,0,0-2.108,1.444,6.706,6.706,0,0,0-1.384,2.2,8.134,8.134,0,0,0,0,5.6,6.6,6.6,0,0,0,1.384,2.18,6.266,6.266,0,0,0,2.108,1.428A6.9,6.9,0,0,0,236.143,475.135Z"
            transform="translate(-127.153 -457.707)"
            fill="currentColor"
          />
          <path
            id="Path_11291"
            data-name="Path 11291"
            d="M251.489,458.025v16.7h6.256v3.368H247.637V458.025Z"
            transform="translate(-117.703 -457.572)"
            fill="currentColor"
          />
          <path
            id="Path_11292"
            data-name="Path 11292"
            d="M271.309,457.707a11.709,11.709,0,0,1,4.349.783,10.092,10.092,0,0,1,3.387,2.184,9.873,9.873,0,0,1,2.2,3.326,11.707,11.707,0,0,1,0,8.424,9.739,9.739,0,0,1-2.2,3.31,10.146,10.146,0,0,1-3.387,2.168,12.518,12.518,0,0,1-8.7,0,10.075,10.075,0,0,1-3.387-2.168,9.78,9.78,0,0,1-2.2-3.31,11.787,11.787,0,0,1,0-8.424,9.915,9.915,0,0,1,2.2-3.326,10.022,10.022,0,0,1,3.387-2.184A11.715,11.715,0,0,1,271.309,457.707Zm0,17.428a6.939,6.939,0,0,0,2.7-.513,6.316,6.316,0,0,0,2.107-1.428,6.666,6.666,0,0,0,1.383-2.18,8.068,8.068,0,0,0,0-5.6,6.773,6.773,0,0,0-1.383-2.2A6.212,6.212,0,0,0,274,461.771a7.374,7.374,0,0,0-5.391,0,6.311,6.311,0,0,0-2.107,1.444,6.746,6.746,0,0,0-1.381,2.2,8.156,8.156,0,0,0,0,5.6,6.639,6.639,0,0,0,1.381,2.18,6.418,6.418,0,0,0,2.107,1.428A6.914,6.914,0,0,0,271.309,475.135Z"
            transform="translate(-112.188 -457.707)"
            fill="currentColor"
          />
          <path
            id="Path_11293"
            data-name="Path 11293"
            d="M292.452,467.49h9.173v.239c0,.341-.023.837-.074,1.494a12.594,12.594,0,0,1-.362,2.151,11.767,11.767,0,0,1-.884,2.4,8.6,8.6,0,0,1-1.622,2.256,8.078,8.078,0,0,1-3.4,2.105,13.111,13.111,0,0,1-3.732.54,14.656,14.656,0,0,1-1.879-.118,10.381,10.381,0,0,1-1.882-.422,8.7,8.7,0,0,1-1.82-.827,8.514,8.514,0,0,1-1.7-1.34c-.178-.177-.439-.47-.771-.87a9.272,9.272,0,0,1-.989-1.568,11.785,11.785,0,0,1-.86-2.288,11.421,11.421,0,0,1-.359-3.006,11.185,11.185,0,0,1,.287-2.636,9.981,9.981,0,0,1,.74-2.1,9.281,9.281,0,0,1,.956-1.6,11.653,11.653,0,0,1,.968-1.13,11.836,11.836,0,0,1,1.324-1.128,8.647,8.647,0,0,1,1.729-.979,12.21,12.21,0,0,1,2.2-.694,12.386,12.386,0,0,1,2.657-.269,11.861,11.861,0,0,1,2.318.23,8.251,8.251,0,0,1,2.314.824,10.269,10.269,0,0,1,1.974,1.417,9.045,9.045,0,0,1,1.79,2.257l-3.351,1.835a4.69,4.69,0,0,0-1.18-1.579,6.228,6.228,0,0,0-1.167-.794,5.1,5.1,0,0,0-1.391-.486,7.218,7.218,0,0,0-3.919.285,5.825,5.825,0,0,0-2.188,1.347,6.372,6.372,0,0,0-1.457,2.207,7.938,7.938,0,0,0-.572,3.082,7.755,7.755,0,0,0,.194,1.808,8.208,8.208,0,0,0,.482,1.445,5.564,5.564,0,0,0,.637,1.085,6.161,6.161,0,0,0,.674.751,7.156,7.156,0,0,0,1.769,1.187,6.055,6.055,0,0,0,2.7.529,7.723,7.723,0,0,0,2.053-.291,5.467,5.467,0,0,0,1.9-.915,5.173,5.173,0,0,0,1.133-1.219,5.445,5.445,0,0,0,.77-1.849h-5.176Z"
            transform="translate(-103.387 -457.707)"
            fill="currentColor"
          />
          <path
            id="Path_11294"
            data-name="Path 11294"
            d="M307.555,468.915l-6.891-10.89H305.3l4.269,6.98,4.278-6.98h4.633l-7.075,10.89v9.177h-3.847Z"
            transform="translate(-95.142 -457.572)"
            fill="currentColor"
          />
        </g>
      </svg>
    </LogoWrap>
  );
}

const LogoWrap = styled(Link)`
  color: #ffff;
  display: inline-block;
  
  .logo-svg{
    max-width: 50vw;
  }
`;
