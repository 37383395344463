import styled from "@emotion/styled/";
import React from "react";
import {useAppSelector} from "../../../store";
import Logo from "../../common/Logo";
import HamburgerMenu from "./HamburgerMenu";
import {StaticImage} from "gatsby-plugin-image";
import StoreButton from "./StoreButton";

export default function Header() {
    const isOverlayShow = useAppSelector(
        (state: any) => state.MenuSlice.isOverlayShow
    );

    return (
        <HeaderWrap>
            <HeaderFlex className="header__inner" isOverlayShow={isOverlayShow}>
                <Logo></Logo>
            </HeaderFlex>
            <div className="header-left">
                <HamburgerMenu/>
            </div>
            <div className="header-right">
                <StoreButton/>
            </div>

        </HeaderWrap>
    );
}

const HeaderWrap = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 60;
  padding-top: 20px;
  color: #FFF;

  .header-right {
    position: absolute;
    right: 1em;
  }

  .header-left {
    position: absolute;
    left: 1em;
    margin-top:2px;
  }

  .cart-button {

    text-transform: uppercase;
  }

`;

const HeaderFlex = styled.div<{ isOverlayShow: boolean }>`
  opacity: ${(props) => (props.isOverlayShow ? "0" : "1")};
  transition: opacity 0.4s ease-out;
`;

const MenuWrap = styled.div`

`;
