import styled from "@emotion/styled";
import React from "react";
import Logo from "../../common/Logo";
import {FacebookLogo, InstagramLogo, NoteLogo, TwitterLogo} from "../../common/SocialLogo";
import {Link} from "gatsby";
import FooterOnlineButton from "./FooterOnlineButton";

export default function Footer() {
    return (
        <StyledFooter>
            <div className="footer-wrap">
                <Logo></Logo>

                <div className="links">
                    <div className="left_block">
                        <div className="bl_online">
                            <FooterOnlineButton/>
                        </div>
                        <div className="bl_page_links">
                            <Link className="link-text" to="/about">About</Link>
                            <Link className="link-text" to="/contact">Contact</Link>
                            <Link className="link-text" to="/studio">Studio</Link>
                            <Link className="link-text" to="/product">Product</Link>
                        </div>
                        <div className="bl_series_links">
                            <Link className="link-text" to="/workwear">Work Wear</Link>
                            <Link className="link-text" to="/texturelab">Texture LAB</Link>
                        </div>
                    </div>
                    <div className="right_block">
                        <FooterSocial>
                            {/*<TwitterLogo color="white"/>*/}
                            <InstagramLogo color="white"/>
                            <FacebookLogo color="white"/>
                            {/*<NoteLogo color="white"></NoteLogo>*/}
                        </FooterSocial>
                    </div>
                </div>
            </div>
        </StyledFooter>
    );
}

const StyledFooter = styled.footer`
  background-color: #736f6d;
  width: 100%;
  display: flex;
  justify-content: center;

  .footer-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 78px 20px 107px;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;

    flex-direction: column;
  }

  .link-text {

    //font-family: Didot;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.06em;
    line-height: 42px;
    text-align: left;
    color: #fff;
  }

  .link-text:first-of-type {
    margin-right: 1em;
  }

  .link-text:nth-of-type(n+2) {
    margin: 10px 0 0;
  }

  .bl_series_links {
    margin: 10px 0 30px 10px;
    display: flex;
    flex-direction: column;

    .link-text {
      color: #BEBBB9;
      display: flex;
      align-items: center;
    }

    .link-text:before {
      display: inline-block;
      border-top: 1px solid;
      content: "";
      width: 20px;
      margin-right: 20px;
      
    }
  }

  .bl_online {
    margin-top: 30px;
  }

  .bl_page_links {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
`;


const FooterSocial = styled.div`
  width: 100%;
  margin-top: 16px;

  a:nth-of-type(n+2) {
    margin-left: 28px;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    a:nth-of-type(n+2) {
      margin-left: 0px;
    }

  }
`;
