import styled from "@emotion/styled";
import {Link} from "gatsby";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../store";
import {setIsOverlayShow} from "../../../store/MenuSlice";

export default function NavOverlay() {

    const isOverlayShow = useAppSelector(
        (state: any) => state.MenuSlice.isOverlayShow
    );

    const dispatch = useAppDispatch();

    const hideMenu = () => {
        dispatch(setIsOverlayShow(false));
    }

    return (
        <Overlay isOpen={isOverlayShow}>
            <nav className="menus ly_menu">
                <ul>
                    <li><Link className="top_link menu_link" onClick={hideMenu} to="/"><span>Top</span></Link></li>
                    <li><Link className="top_link menu_link" onClick={hideMenu} to="/about"><span>About</span></Link></li>
                    <li><Link className="top_link menu_link" onClick={hideMenu} to="/product"><span>Product</span></Link></li>
                    <li><Link className="category_link menu_link" onClick={hideMenu} to="/workwear"><span>WORK WEAR</span></Link></li>
                    <li><Link className="category_link menu_link" onClick={hideMenu} to="/texturelab"><span>TEXTURE LAB</span></Link></li>
                    {/*<li><Link className="category_link menu_link" onClick={hideMenu} to="/homecouture">HOME COUTURE</Link></li>*/}
                    <li><Link className="top_link menu_link" onClick={hideMenu} to="/studio"><span>Studio</span></Link></li>
                    <li><a href="https://shop.knitology.net/blogs/journal" className="top_link menu_link"><span>Blog</span></a></li>
                    <li><a href="https://shop.knitology.net/" className="top_link menu_link"><span>online shop</span></a></li>
                </ul>
            </nav>
        </Overlay>
    );
}

const Overlay = styled.div<{ isOpen: boolean }>`

  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
  
  background: rgb(0, 0, 0);
  opacity: ${(props) => (props.isOpen ? "0.9" : "0")};
  display: ${(props) => (props.isOpen ? "block" : "none")};

  .ly_menu {
    padding: 3em 100px;

    @media (max-width: 768px) {
        padding-left: 26px;
        padding: 3em 26px;
    }
  }
  
  .menus {
    //font-family: "Didot";
    font-weight: normal;
    overflow-y: scroll;

    .menu_link {
      display: inline-block;
      cursor: pointer;
      text-align: left;
      letter-spacing: 0.06em;
      
      opacity: ${(props) => (props.isOpen ? "1" : "0")};
      transition: ${(props) => props.isOpen
              ? "opacity .6s ease-out 0s, transform .6s .2s ease-out"
              : ""};
    }
  }

  .top_link {
    
    margin-top: 1em;
    
    @media (max-width: 768px) {
      margin-top: 4vw;
    }
    
    span{
      font-weight: normal;
      font-size: 32px;
      letter-spacing: 0.06em;
      line-height: 53px;
      text-align: left;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 6vw;
      }
    }
  }
  
  .category_link{
    display: inline-block;
    margin-left: 1em;
    margin-top: 1.5em;
    
    @media (max-width: 768px) {
      margin-top: 3.4vw;
    }

    span{

      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 28px;
      letter-spacing: 0.06em;
      line-height: 53px;
      text-align: left;
      color: #aaa;
      
      @media (max-width: 768px) {
        font-size: 6vw;
      }
    }

    span:before {
      display: inline-block;
      border-top: 1px solid #999 ;
      content: "";
      width: 30px;
      margin-right: 30px;
      
      @media (max-width: 768px) {
        
        width: 6vw;
        margin-right: 6vw;
      }
    }
  }
`;
