import styled from "@emotion/styled";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setIsOverlayShow } from "../../../store/MenuSlice";

export default function HamburgerMenu() {
  const isOverlayShow = useAppSelector(
    (state: any) => state.MenuSlice.isOverlayShow
  );

  const dispatch = useAppDispatch();

  return (
    <HamburgerWrap
      isOpen={isOverlayShow}
      onClick={() => {
        console.log("onclick menu");
        dispatch(setIsOverlayShow(!isOverlayShow));
      }}
    >
      <span></span>
      <span></span>
      <span></span>
    </HamburgerWrap>
  );
}

const HamburgerWrap = styled.div<{ isOpen: boolean }>`
  height: 16px;
  width: 23px;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 100;

  span {

    position: absolute;
    left: 0;
    border-radius: 4px;
    display: block;
    height: 2px;
    width: 100%;
    background-color: white;
    
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
  }
  span:nth-of-type(1) {
    top: 0;
  }
  span:nth-of-type(2) {
    top: 7px;
  }
  span:nth-of-type(3) {
    bottom: 0;
  }
  
  span:nth-of-type(1) {
    transform: ${(props) => props.isOpen ? "translateY(9px) rotate(45deg)" : "translateY(0px) rotate(0deg)"};
  }
  span:nth-of-type(2) {
    opacity: ${(props) => props.isOpen ? 0 : 1};
  }
  span:nth-of-type(3) {
    transform: ${(props) => props.isOpen ? "translateY(-5px) rotate(-45deg)" : "translateY(0px) rotate(0deg)"};
  }
  
`;

