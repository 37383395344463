import styled from "@emotion/styled/";
import React from "react";
import storePcImage from "../../../assets/common/store_button_pc.svg"
import storeSpImage from "../../../assets/common/store.svg"


export default function StoreButton() {

    return (
        <StoreButtonWrapper className="cart-button">
            <a className="store-button" href="https://shop.knitology.net/"></a>
        </StoreButtonWrapper>
    );
}

const StoreButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  align-items: flex-start;
  color: #00F;
  text-decoration: none;
  cursor: pointer;
  content: "";

  .store-button:before {
    position: absolute;
    content: "";
    width: 72px;
    height: 20px;
    top: 50%;
    right: 10px;
    margin: -14px 0 0 0;
    background-image: url(${storePcImage});
    background-repeat: no-repeat;
    
  }

  @media (max-width: 768px) {
    .store-button:before {
      width: 17px;
      height: 20px;
      right: 0px;
      background-image: url(${storeSpImage});
    }
  }
  
  
`;
