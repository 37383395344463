import styled from "@emotion/styled";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    fab,
    faFacebook,
    faFacebookF,
    faInstagram,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

library.add(fab, faTwitter, faInstagram, faFacebook, faFacebookF);

interface LogoProps {
    color: string;
}

export function TwitterLogo(props: LogoProps) {
    return (
        <a href="https://twitter.com/oni_knitology">
            <LogoWrap color={props.color}>
                <FontAwesomeIcon icon={["fab", "twitter"]}/>
                <span>Twitter</span>
            </LogoWrap>
        </a>
    );
}

export function InstagramLogo(props: LogoProps) {
    return (
        <a href="https://www.instagram.com/knitology_/">
            <LogoWrap color={props.color}>
                <FontAwesomeIcon icon={["fab", "instagram"]}/>
                <span>Instagram</span>
            </LogoWrap>
        </a>
    );
}

export function FacebookLogo(props: LogoProps) {
    return (
        <a href="https://www.facebook.com/Knitology/">
            <LogoWrap color={props.color}>
                <FontAwesomeIcon icon={["fab", "facebook"]}/>
                <span>facebook</span>
            </LogoWrap>
        </a>
    );
}

export function NoteLogo(props: LogoProps) {
    return (
        <a href="https://note.com/knitology/">
            <LogoWrap color={props.color}>
                <SvgWrap
                    viewBox="0 0 512 512"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M202.6,173.5v-39.2c0-7.1,0.4-9.5,1.5-13.2c2.9-10.1,12.9-17.6,24.6-17.6
		c11.7,0,21.7,7.7,24.6,17.6c1.1,3.7,1.5,6,1.5,13.2v60.4c0,3.7,0,7.3-0.7,10.6c-2,9.1-10.3,17.4-19.5,19.4c-3.3,0.7-7,0.7-10.6,0.7
		h-60.6c-7.2,0-9.5-0.4-13.2-1.5c-9.9-2.9-17.6-12.8-17.6-24.5c0-11.7,7.7-21.6,17.6-24.5c3.7-1.1,6.1-1.5,13.2-1.5H202.6z
		 M418.4,447.2H93.6V161.9c0-3.7,1.3-6.8,3.9-9.3l84.2-84c2.6-2.6,5.7-3.8,9.4-3.8h227.4V447.2z M455.3,0.4
		c-1.7-0.2-3.9-0.4-8.1-0.4H182.4c-2.9,0-5.9,0.2-7.9,0.4c-12.1,1.1-22.9,6.8-31.6,15.4L44.4,114c-8.6,8.6-14.3,19.4-15.4,31.5
		c-0.2,2-0.4,4.9-0.4,7.9V476c0,4.2,0.2,6.4,0.4,8.1c1.5,13.7,13.9,26.2,27.7,27.6c1.7,0.2,3.9,0.4,8.1,0.4h382.5
		c4.2,0,6.4-0.2,8.1-0.4c13.8-1.5,26.2-13.9,27.7-27.6c0.2-1.6,0.4-3.8,0.4-8.1V36c0-4.2-0.2-6.4-0.4-8.1
		C481.6,14.3,469.1,1.8,455.3,0.4z"
                        fill="currentColor"
                    />
                </SvgWrap>
                <span>Note</span>
            </LogoWrap>
        </a>
    );
}

const LogoWrap = styled.div<{ color: string }>`
  display: inline;
  //width: 17px;
  //height: 17px;
  color: ${(props) => props.color};
  text-wrap: none;
  
  span{
    padding-left: 0.6em;
    //font-family: Didot;
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 46px;
    text-align: left;
    color: #fff;
  }
`;

const SvgWrap = styled.svg`
  font-size: inherit;
  height: 1em;
  vertical-align: -0.125em;
  width: 0.875em;
`;
