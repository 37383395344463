import React from "react";
import Header from "./Header";
import NavOverlay from "./Header/NavOverlay";
import Footer from "./Footer";
import Helmet from "react-helmet"

interface LayoutProps {
    children?: React.ReactNode;
}

export default function Layout(props: LayoutProps) {
    return (
        <>
            <Helmet>
                <script src="https://polyfill.io/v3/polyfill.min.js?features=fetch%2CPromise"></script>
            </Helmet>
            <Header></Header>
            <NavOverlay></NavOverlay>
            {props.children}
            <Footer></Footer>
        </>
    );
}
